export const FETCH_TRIP_QUOTE_LEADS_REQUEST = "FETCH_TRIP_QUOTE_LEADS_REQUEST";
export const FETCH_TRIP_QUOTE_LEADS_SUCCESS = "FETCH_TRIP_QUOTE_LEADS_SUCCESS";
export const FETCH_TRIP_QUOTE_LEADS_FAILURE = "FETCH_TRIP_QUOTE_LEADS_FAILURE";

export const FETCH_TRIPS_REQUEST = "FETCH_TRIPS_REQUEST";
export const FETCH_TRIPS_SUCCESS = "FETCH_TRIPS_SUCCESS";
export const FETCH_TRIPS_FAILURE = "FETCH_TRIPS_FAILURE";

export const FETCH_TRIP_DETAILS_REQUEST = "FETCH_TRIP_DETAILS_REQUEST";
export const FETCH_TRIP_DETAILS_SUCCESS = "FETCH_TRIP_DETAILS_SUCCESS";
export const FETCH_TRIP_DETAILS_FAILURE = "FETCH_TRIP_DETAILS_FAILURE";

export const FETCH_PICKLIST_REQUEST = "FETCH_PICKLIST_REQUEST";
export const FETCH_PICKLIST_SUCCESS = "FETCH_PICKLIST_SUCCESS";
export const FETCH_PICKLIST_FAILURE = "FETCH_PICKLIST_FAILURE";

export const FETCH_TRIP_POOL_QUOTES_REQUEST = "FETCH_TRIP_POOL_QUOTES_REQUEST";
export const FETCH_TRIP_POOL_QUOTES_SUCCESS = "FETCH_TRIP_POOL_QUOTES_SUCCESS";
export const FETCH_TRIP_POOL_QUOTES_FAILURE = "FETCH_TRIP_POOL_QUOTES_FAILURE";

export const FETCH_ORG_TRIP_POOL_CONFIG_REQUEST = "FETCH_ORG_TRIP_POOL_CONFIG_REQUEST";
export const FETCH_ORG_TRIP_POOL_CONFIG_SUCCESS = "FETCH_ORG_TRIP_POOL_CONFIG_SUCCESS";
export const FETCH_ORG_TRIP_POOL_CONFIG_FAILURE = "FETCH_ORG_TRIP_POOL_CONFIG_FAILURE";

export const FETCH_SUB_ORG_TRIP_POOL_CONFIG_REQUEST = "FETCH_SUB_ORG_TRIP_POOL_CONFIG_REQUEST";
export const FETCH_SUB_ORG_TRIP_POOL_CONFIG_SUCCESS = "FETCH_SUB_ORG_TRIP_POOL_CONFIG_SUCCESS";
export const FETCH_SUB_ORG_TRIP_POOL_CONFIG_FAILURE = "FETCH_SUB_ORG_TRIP_POOL_CONFIG_FAILURE";

export const FETCH_TRIP_POOL_SUB_ORGANISATIONS_REQUEST = "FETCH_TRIP_POOL_SUB_ORGANISATIONS_REQUEST";
export const FETCH_TRIP_POOL_SUB_ORGANISATIONS_SUCCESS = "FETCH_TRIP_POOL_SUB_ORGANISATIONS_SUCCESS";
export const FETCH_TRIP_POOL_SUB_ORGANISATIONS_FAILURE = "FETCH_TRIP_POOL_SUB_ORGANISATIONS_FAILURE";

export const FETCH_ORGANISATION_TRIP_TYPES_REQUEST = "FETCH_ORGANISATION_TRIP_TYPES_REQUEST";
export const FETCH_ORGANISATION_TRIP_TYPES_SUCCESS = "FETCH_ORGANISATION_TRIP_TYPES_SUCCESS";
export const FETCH_ORGANISATION_TRIP_TYPES_FAILURE = "FETCH_ORGANISATION_TRIP_TYPES_FAILURE";

export const FETCH_ORGANISATION_PROFILE_REQUEST = "FETCH_ORGANISATION_PROFILE_REQUEST";
export const FETCH_ORGANISATION_PROFILE_SUCCESS = "FETCH_ORGANISATION_PROFILE_SUCCESS";
export const FETCH_ORGANISATION_PROFILE_FAILURE = "FETCH_ORGANISATION_PROFILE_FAILURE";

export const FETCH_ADDED_SERVICES_REQUEST = "FETCH_ADDED_SERVICES_REQUEST";
export const FETCH_ADDED_SERVICES_SUCCESS = "FETCH_ADDED_SERVICES_SUCCESS";
export const FETCH_ADDED_SERVICES_FAILURE = "FETCH_ADDED_SERVICES_FAILURE";

export const FETCH_INVENTORY_PROFILE_REQUEST = "FETCH_INVENTORY_PROFILE_REQUEST";
export const FETCH_INVENTORY_PROFILE_SUCCESS = "FETCH_INVENTORY_PROFILE_SUCCESS";
export const FETCH_INVENTORY_PROFILE_FAILURE = "FETCH_INVENTORY_PROFILE_FAILURE";

export const FETCH_RECONCILIATION_PROFILE_REQUEST = "FETCH_RECONCILIATION_PROFILE_REQUEST";
export const FETCH_RECONCILIATION_PROFILE_SUCCESS = "FETCH_RECONCILIATION_PROFILE_SUCCESS";
export const FETCH_RECONCILIATION_PROFILE_FAILURE = "FETCH_RECONCILIATION_PROFILE_FAILURE";


/****************PRODUCTS*************/
export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";

export const FETCH_PRODUCT_INFORMATION_REQUEST = "FETCH_PRODUCT_INFORMATION_REQUEST";
export const FETCH_PRODUCT_INFORMATION_SUCCESS = "FETCH_PRODUCT_INFORMATION_SUCCESS";
export const FETCH_PRODUCT_INFORMATION_FAILURE = "FETCH_PRODUCT_INFORMATION_FAILURE";

export const FETCH_PRODUCT_CATEGORIES_REQUEST = "FETCH_PRODUCT_CATEGORIES_REQUEST";
export const FETCH_PRODUCT_CATEGORIES_SUCCESS = "FETCH_PRODUCT_CATEGORIES_SUCCESS";
export const FETCH_PRODUCT_CATEGORIES_FAILURE = "FETCH_PRODUCT_CATEGORIES_FAILURE";


/****************ORDERS AND ORDERSETS*************/

export const FETCH_ORDER_SETS_REQUEST = "FETCH_ORDER_SETS_REQUEST";
export const FETCH_ORDER_SETS_SUCCESS = "FETCH_ORDER_SETS_SUCCESS";
export const FETCH_ORDER_SETS_FAILURE = "FETCH_ORDER_SETS_FAILURE";

export const FETCH_ORDER_SETS_INFORMATION_REQUEST = "FETCH_ORDER_SETS_INFORMATION_REQUEST";
export const FETCH_ORDER_SETS_INFORMATION_SUCCESS = "FETCH_ORDER_SETS_INFORMATION_SUCCESS";
export const FETCH_ORDER_SETS_INFORMATION_FAILURE = "FETCH_ORDER_SETS_INFORMATION_FAILURE";

export const FETCH_PREPARED_ORDER_SETS_REQUEST = "FETCH_PREPARED_ORDER_SETS_REQUEST";
export const FETCH_PREPARED_ORDER_SETS_SUCCESS = "FETCH_PREPARED_ORDER_SETS_SUCCESS";
export const FETCH_PREPARED_ORDER_SETS_FAILURE = "FETCH_PREPARED_ORDER_SETS_FAILURE";
