import {Loading} from "../../utils/helpers/constants";
import * as Constants from "../constants/booking.constants";
import {formatOrderSets, formatProducts, formatTripTypeByCategory, formatTripTypes} from "../../utils/helpers/logicHelper";


const initialState = {
	tripTypes: [],
	trips: [],
	orderSets: [],
	preparedOrderSets: [],
	orderSetInfo: {},
	tripQuoteLeads: [],
	tripPoolQuotes: [],
	orgtripPoolConfig: [],
	subOrgtripPoolConfig: [],
	businessTripTypes: [],
	customerTripTypes: [],
	tripPoolSubOrganisations: [],
	trip: {},
	picklist: {},
	bookingProfile: {},
	inventoryProfile: {},
	bookingAddedServices: {},
	reconciliationProfile: {},
	tripsCount: "",
	orgTripPoolConfigCount: "",
	subOrgTripPoolConfigCount: "",
	preparedOrderSetsCounts: "",
	orderSetsCounts: "",
	productsCount: "",
	tripQuoteLeadsCount: "",
	productCategoriesCount: "",
	loadingTripTypes: "",
	loadingTrips: "",
	loadingTripInfo: "",
	loadingPicklist: "",
	loadingTripPoolQuotes: "",
	loadingOrgTripPoolConfig: "",
	loadingSubOrgTripPoolConfig: "",
	loadingInventoryConfig: "",
	loadingOrgBookingConfig: "",
	loadingTripQuoteLeadsCount: "",
	loadingBookingAddedServices: "",
	loadingReconciliationConfig: "",
	loadingProductCategories: "",
	loadingProducts: "",
	loadingProductInfo: "",
	loadingOrderSets: "",
	loadingPreparedOrderSets: "",
	loadingOrderSetInfo: "",
	loadingTripPoolSubOrganisations: "",
	Products: [],
	ProductInfo: {},
	productCategories: [],
};

const bookingsReducer = (state = initialState, action) => {
	switch(action.type) {

		case Constants.FETCH_ORGANISATION_TRIP_TYPES_REQUEST:
			return {...state, loadingTripTypes: Loading.FETCHING};

		case Constants.FETCH_ORGANISATION_TRIP_TYPES_SUCCESS:
			const tripTypes = formatTripTypes(action.payload);
			const businessTripTypes = formatTripTypeByCategory(action.payload, 'BUSINESS');
			const customerTripTypes = formatTripTypeByCategory(action.payload, 'CUSTOMER');
			return {...state, tripTypes, businessTripTypes, customerTripTypes, loadingTripTypes: Loading.SUCCESS};

		case Constants.FETCH_ORGANISATION_TRIP_TYPES_FAILURE:
			return {...state, loadingTripTypes: Loading.ERROR};

		case Constants.FETCH_TRIP_QUOTE_LEADS_REQUEST:
			return {...state, loadingTripQuoteLeadsCount: Loading.FETCHING};

		case Constants.FETCH_TRIP_QUOTE_LEADS_SUCCESS:
			return {
				...state,
				loadingTripQuoteLeadsCount: Loading.SUCCESS,
				tripQuoteLeads: action.payload.data,
				tripQuoteLeadsCount: action.payload.count
			};

		case Constants.FETCH_TRIP_QUOTE_LEADS_FAILURE:
			return {...state, loadingTripQuoteLeadsCount: Loading.ERROR};

		case Constants.FETCH_TRIPS_REQUEST:
			return {...state, loadingTrips: Loading.FETCHING};

		case Constants.FETCH_TRIPS_SUCCESS:
			return {...state, loadingTrips: Loading.SUCCESS, trips: action.payload.data, tripsCount: action.payload.count};

		case Constants.FETCH_TRIPS_FAILURE:
			return {...state, loadingTrips: Loading.ERROR};

		case Constants.FETCH_TRIP_DETAILS_REQUEST:
			return {...state, loadingTripInfo: Loading.FETCHING};

		case Constants.FETCH_TRIP_DETAILS_SUCCESS:
			let trip = action.payload.length < 1 ? {} : action.payload[0];
			return {...state, loadingTripInfo: Loading.SUCCESS, trip};

		case Constants.FETCH_TRIP_DETAILS_FAILURE:
			return {...state, loadingTripInfo: Loading.ERROR};

		case Constants.FETCH_PICKLIST_REQUEST:
			return {...state, loadingPicklist: Loading.FETCHING};

		case Constants.FETCH_PICKLIST_SUCCESS:
			return {...state, loadingPicklist: Loading.SUCCESS, picklist: action.payload};

		case Constants.FETCH_PICKLIST_FAILURE:
			return {...state, loadingPicklist: Loading.ERROR}

		case Constants.FETCH_TRIP_POOL_QUOTES_REQUEST:
			return {...state, loadingTripPoolQuotes: Loading.FETCHING};

		case Constants.FETCH_TRIP_POOL_QUOTES_SUCCESS:
			return {...state, loadingTripPoolQuotes: Loading.SUCCESS, tripPoolQuotes: action.payload};

		case Constants.FETCH_TRIP_POOL_QUOTES_FAILURE:
			return {...state, loadingTripPoolQuotes: Loading.ERROR};


		case Constants.FETCH_ORG_TRIP_POOL_CONFIG_REQUEST:
			return {...state, loadingOrgTripPoolConfig: Loading.FETCHING};

		case Constants.FETCH_ORG_TRIP_POOL_CONFIG_SUCCESS:
			return {
				...state,
				loadingOrgTripPoolConfig: Loading.SUCCESS,
				orgTripPoolConfig: action.payload.data,
				orgTripPoolConfigCount: action.payload.count
			};

		case Constants.FETCH_ORG_TRIP_POOL_CONFIG_FAILURE:
			return { ...state, loadingOrgTripPoolConfig: Loading.ERROR};


		case Constants.FETCH_SUB_ORG_TRIP_POOL_CONFIG_REQUEST:
			return { ...state, loadingSubOrgTripPoolConfig: Loading.FETCHING};

		case Constants.FETCH_SUB_ORG_TRIP_POOL_CONFIG_SUCCESS:
			return {
				...state,
				loadingSubOrgTripPoolConfig: Loading.SUCCESS,
				subOrgtripPoolConfig: action.payload.data,
				subOrgTripPoolConfigCount: action.payload.count
			};

		case Constants.FETCH_SUB_ORG_TRIP_POOL_CONFIG_FAILURE:
			return { ...state, loadingSubOrgTripPoolConfig: Loading.ERROR};


		case Constants.FETCH_TRIP_POOL_SUB_ORGANISATIONS_REQUEST:
			return {...state, loadingTripPoolSubOrganisations: Loading.FETCHING};

		case Constants.FETCH_TRIP_POOL_SUB_ORGANISATIONS_SUCCESS:
			return {
				...state, loadingTripPoolSubOrganisations: Loading.SUCCESS, tripPoolSubOrganisations: action.payload,
			};

		case Constants.FETCH_TRIP_POOL_SUB_ORGANISATIONS_FAILURE:
			return {...state, loadingTripPoolSubOrganisations: Loading.ERROR};

		case Constants.FETCH_ADDED_SERVICES_REQUEST:
			return {...state, loadingBookingAddedServices: Loading.FETCHING};

		case Constants.FETCH_ADDED_SERVICES_SUCCESS:
			let bookingAddedServices = action.payload.length < 1 ? {} : action.payload[0];
			return {...state, loadingBookingAddedServices: Loading.SUCCESS, bookingAddedServices};

		case Constants.FETCH_ADDED_SERVICES_FAILURE:
			return {...state, loadingBookingAddedServices: Loading.ERROR};

		case Constants.FETCH_ORGANISATION_PROFILE_REQUEST:
			return {...state, loadingOrgBookingConfig: Loading.FETCHING};

		case Constants.FETCH_ORGANISATION_PROFILE_SUCCESS:
			let bookingProfile = action.payload.length < 1 ? {} : action.payload[0];
			return {...state, loadingOrgBookingConfig: Loading.SUCCESS, bookingProfile};

		case Constants.FETCH_ORGANISATION_PROFILE_FAILURE:
			return {...state, loadingOrgBookingConfig: Loading.ERROR};

		case Constants.FETCH_INVENTORY_PROFILE_REQUEST:
			return {...state, loadingInventoryConfig: Loading.FETCHING};

		case Constants.FETCH_INVENTORY_PROFILE_SUCCESS:
			let inventoryProfile = action.payload.length < 1 ? {} : action.payload[0];
			return {...state, loadingInventoryConfig: Loading.SUCCESS, inventoryProfile};

		case Constants.FETCH_INVENTORY_PROFILE_FAILURE:
			return {...state, loadingInventoryConfig: Loading.ERROR};

		case Constants.FETCH_RECONCILIATION_PROFILE_REQUEST:
			return {...state, loadingReconciliationConfig: Loading.FETCHING};

		case Constants.FETCH_RECONCILIATION_PROFILE_SUCCESS:
			let reconciliationProfile = action.payload.length < 1 ? {} : action.payload[0];
			return {...state, loadingReconciliationConfig: Loading.SUCCESS, reconciliationProfile};

		case Constants.FETCH_RECONCILIATION_PROFILE_FAILURE:
			return {...state, loadingReconciliationConfig: Loading.ERROR};

		case Constants.FETCH_PRODUCTS_REQUEST:
			return {...state, loadingProducts: Loading.FETCHING};

		case Constants.FETCH_PRODUCTS_SUCCESS:
			const products = formatProducts(action.payload.data);
			return {
				...state,
				products,
				loadingProducts: Loading.SUCCESS,
				productsCount: action.payload.count,
			}

		case Constants.FETCH_PRODUCTS_FAILURE:
			return {...state, loadingProducts: Loading.ERROR};

		case Constants.FETCH_PRODUCT_INFORMATION_REQUEST:
			return {...state, loadingProductInfo: Loading.FETCHING};

		case Constants.FETCH_PRODUCT_INFORMATION_SUCCESS:
			return {...state, loadingProductInfo: Loading.SUCCESS, productInfo: action.payload}

		case Constants.FETCH_PRODUCT_INFORMATION_FAILURE:
			return {...state, loadingProductInfo: Loading.ERROR};

		case Constants.FETCH_PRODUCT_CATEGORIES_REQUEST:
			return {...state, loadingProductCategories: Loading.FETCHING};

		case Constants.FETCH_PRODUCT_CATEGORIES_SUCCESS:
			return {
				...state,
				loadingProductCategories: Loading.SUCCESS,
				productCategoriesCount: action.payload.count,
				productCategories: action.payload.data
			}

		case Constants.FETCH_PRODUCT_CATEGORIES_FAILURE:
			return {...state, loadingProductCategories: Loading.ERROR};

		case Constants.FETCH_ORDER_SETS_REQUEST:
			return {...state, loadingOrderSets: Loading.FETCHING};

		case Constants.FETCH_ORDER_SETS_SUCCESS:
			return {
				...state,
				loadingOrderSets: Loading.SUCCESS,
				orderSetsCounts: action.payload.count,
				orderSets: action.payload.data
			}

		case Constants.FETCH_ORDER_SETS_FAILURE:
			return {...state, loadingOrderSets: Loading.ERROR};


		case Constants.FETCH_PREPARED_ORDER_SETS_REQUEST:
			return { ...state, loadingPreparedOrderSets: Loading.FETCHING};

		case Constants.FETCH_PREPARED_ORDER_SETS_SUCCESS:
			const preparedOrderSets = formatOrderSets(action.payload.data);
			return {
				...state,
				loadingPreparedOrderSets: Loading.SUCCESS,
				preparedOrderSetsCounts: action.payload.count,
				preparedOrderSets
			}

		case Constants.FETCH_PREPARED_ORDER_SETS_FAILURE:
			return { ...state, loadingPreparedOrderSets: Loading.ERROR};


		case Constants.FETCH_ORDER_SETS_INFORMATION_REQUEST:
			return {...state, loadingOrderSetInfo: Loading.FETCHING};

		case Constants.FETCH_ORDER_SETS_INFORMATION_SUCCESS:
			return {
				...state, loadingOrderSetInfo: Loading.SUCCESS, orderSetInfo: action.payload
			}

		case Constants.FETCH_ORDER_SETS_INFORMATION_FAILURE:
			return {...state, loadingOrderSetInfo: Loading.ERROR};


		case 'RESET_STATE':
			return initialState;

		default:
			return state;
	}
}

export default bookingsReducer;
