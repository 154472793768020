import {dodgeInstance as Axios} from './axios.service';

const ConfigsService = {
  updateNotificationMatrices(payload) {
    return Axios({
      method: 'PUT',
      url: `locale-service/messaging/tenant/notification-matrix`,
      data: payload
    });
  },

  createRole(payload) {
    return Axios({
      method: 'POST',
      url: `locale-service/configs/roles/create`,
      data: payload
    });
  },

  getRoles(filter) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/roles/${filter}`
    });
  },

  updateRole(id, payload) {
    return Axios({
      method: 'PATCH',
      url: `locale-service/configs/roles/update/${id}`,
      data: payload
    });
  },

  deleteRole(id) {
    return Axios({
      method: 'DELETE',
      url: `locale-service/configs/roles/delete/${id}`,
    });
  },

  updateRolePermission(payload) {
    return Axios({
      method: 'PATCH',
      url: `locale-service/configs/roles/update-permission`,
      data: payload
    });
  },

  getPermissions() {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/permissions`
    });
  },

  getTenantPermissions() {
    return Axios({
      method: 'GET',
      url: `locale-service/tenants/user-permissions`
    });
  },

  updatePermission(payload, slug) {
    return Axios({
      method: 'PATCH',
      url: `locale-service/configs/permissions/update/${slug}`,
      data: payload
    });
  },

  deletePermission(slug) {
    return Axios({
      method: 'DELETE',
      url: `locale-service/configs/permissions/delete/${slug}`
    });
  },

  getSupportedCountries(filter) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/countries/${filter}`,
    });
  },

  addSupportedCountries(payload) {
    return Axios({
      method: 'POST',
      url: `locale-service/configs/countries/create`,
      data: payload
    });
  },

  updateCountry(country, payload) {
    return Axios({
      method: 'PATCH',
      url: `locale-service/configs/countries/update/${country}`,
      data: payload
    });
  },

  deleteSupportedCountry(filter) {
    return Axios({
      method: 'DELETE',
      url: `locale-service/configs/countries/delete/${filter}`
    });
  },

  updateCountryConfig(country, payload) {
    return Axios({
      method: 'PATCH',
      url: `locale-service/configs/countries/update-configs/${country}`,
      data: payload
    });
  },

  getCountryServices(filter) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/services/country/${filter}`,
    });
  },

  configureCountryServiceConfig(payload) {
    return Axios({
      method: 'POST',
      url: `locale-service/configs/services/country/configure/`,
      data: payload
    });
  },

  updateCountryServiceConfig(id, payload) {
    return Axios({
      method: 'PATCH',
      url: `locale-service/configs/services/country/update/${id}`,
      data: payload
    });
  },

  deleteCountryServiceConfig(id) {
    return Axios({
      method: 'DELETE',
      url: `locale-service/configs/services/country/delete/${id}`
    });
  },

  getInsuranceProducts(country) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/insurance/${country}`
    });
  },

  getOrganisationServices(filter) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/services/organisation/${filter}`,
    });
  },

  createService(payload) {
    return Axios({
      method: 'POST',
      url: `locale-service/configs/services/create`,
      data: payload
    });
  },

  getAllService() {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/services`,
    });
  },

  getService(ref) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/services/${ref}`,
    });
  },

  updateService(ref, payload) {
    return Axios({
      method: 'PATCH',
      url: `locale-service/configs/services/update/${ref}`,
      data: payload
    });
  },

  deleteService(ref) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/services/update/${ref}`,
    });
  },

  getRecommendedServices(ref) {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/services/organisation/recommended-services/${ref}`,
    });
  },

  /********************* API KEYS AND WEBHOOKS **************************/
  getIntegrationKeys() {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/integration/retrieve-keys`,
    });
  },

  generatePublicKey() {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/integration/generate-keys`,
    });
  },

  addWebhookURL(payload) {
    return Axios({
      method: 'POST',
      url: `locale-service/configs/integration/webhook-url`,
      data: payload
    });
  },
  getWebhookEvents() {
    return Axios({
      method: 'GET',
      url: `locale-service/configs/integration/get-webhook-events`,
    });
  },
  updateOrgWebhookEvents(payload) {
    return Axios({
      method: 'PUT',
      url: `locale-service/configs/integration/update-webhook-event`,
      data: payload
    });
  },

};

export default ConfigsService;
