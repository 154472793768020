export const PermissionsEnum = {
	TRIP_SEARCH: 'can-search-trip',
	TRIP_QUOTE: 'can-get-trip-quote',
	VIEW_DRIVERS: 'can-list-driver-profile',
	UPDATE_TRIP: 'can-update-trip-customuser',
	DELETE_TRIP: 'can-delete-trip-customuser',
	VIEW_TRIPS: 'can-view-organisation-trips',
	VIEW_WALLET: 'can-view-wallet-information',
	VIEW_FLEET: 'can-view-organisation-vehicle',
	VIEW_A_TRIP: 'can-retreive-organisation-trips',
	VIEW_FLEET_CATEGORY: 'can-view-fleet-category',
	VIEW_USERS: 'can-view-organisation-customuser',
	VIEW_A_BUSINESS: 'can-retreive-business-profile',
	VIEW_TRIP_TYPE: 'can-view-organisation-trip-type',
	VIEW_BUSINESSES: 'can-view-business-profile-list',
	VIEW_TRIP_POOLS: 'can-view-organisation-trip-pool',
	VIEW_VEHICLE_ARRIVAL: 'can-get-arrival-information',
	VIEW_USERS_CONFIG: 'can-view-organisation-user-config',
	VIEW_FLEET_PROFILE: 'can-view-organisation-fleet-profile',
	VIEW_BOOKING_CONFIG: 'can-view-organisation-booking-profile',
	VIEW_DRIVER_PROFILE: 'can-list-organisation-driver-profile',
	CONFIGURE_FLEET_SERVICE: 'can-view-organisation-fleet-profile',
	ENABLE_BOOKING_PROFILE: 'can-create-organisation-booking-profile',
	CONFIGURE_BOOKING_SERVICES: 'can-configure-organisation-services',
	TOGGLE_BOOKING_PROFILE_CONFIG: 'can-toggle-organisation-booking-profile-config',
	CONFIGURE_CUSTOM_BOOKING_SERVICES: 'can-configure-organisation-booking-profile-config',
	VIEW_FLEET_CHARGE: 'can-view-organisation-fleet-category-charge',
	TOGGLE_BOOKING_ADDED_SERVICES: 'can-toggle-organisation-added-services',
	VIEW_ORGANISATION_FLEET_CATEGORY: 'can-view-organisation-fleet-category',
	VIEW_BOOKING_ADDED_SERVICES: 'can-view-booking-organisation-added-services',
	VIEW_WAREHOUSE: "can-view-organisation-warehouse",
	VIEW_PRODUCT: "can-view-warehouse-product",
	VIEW_ORDERSET: "can-view-trip-orderset",
	CREATE_ORDERSET: "can-create-trip-orderset",
	UPDATE_ORDERSET: "can-update-trip-orderset",
	CREATE_PRODUCT: "can-create-warehouse-product",
	UPDATE_PRODUCT: "can-update-warehouse-product",
	CREATE_WAREHOUSE: "can-create-organisation-warehouse",
	UPDATE_CATALOGUE: "can-update-warehouse-catalogue",
	CREATE_CATALOGUE: "can-create-warehouse-catalogue",
	UPDATE_WAREHOUSE: "can-update-organisation-warehouse",
	VIEW_CATALOGUE: "can-view-warehouse-catalogue",
	CANCEL_TRIP: "can-cancel-trip",
	UPDATE_BUSINESS_TRIP_POOL_CONFIG: "can-update-business-trip-pool-config",

	VEHICLE_ARRIVAL: "can-update-vehicle-arrival",
	CREATE_BRANCH: "can-create-business-warehouse",
	VIEW_BRANCH: "can-view-business-warehouse",
	UPDATE_BRANCH: "can-update-business-warehouse",
}
