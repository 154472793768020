import ActionCreator from "../../utils/helpers/actionCreator";
import * as UserConstants from "../constants/users.constants";
import * as ProfileConstants from "../constants/profile.constants";
import * as ConfigsConstants from "../constants/configs.constants";
import * as AnalyticConstants from "../constants/analytics.constants";

import { loadingApp } from "./component.action";
import { expiredTokenCheck } from "./auth.action";
import TokenHelper from "../../utils/helpers/tokenHelper";
import ProfileService from "../../utils/services/profile.service";
import ConfigsService from "../../utils/services/configs.service";
import AnalyticsService from "../../utils/services/analytics.service";

export const getProfile = () => async dispatch => {
  try {
    await dispatch(loadingApp(true));
    await dispatch(ActionCreator('RESET_STATE'));
    let organisation = TokenHelper.getSelectedOrg();
    let country = TokenHelper.getSelectedOrgCountry(), role = TokenHelper.getUserRole();
    let orgObject = {}, queries = [], queryData;
    dispatch(ActionCreator(ProfileConstants.FETCH_PROFILE_REQUEST));
    const { data: responseData } = await ProfileService.getProfile();
    dispatch(ActionCreator(ProfileConstants.FETCH_PROFILE_SUCCESS, responseData.data));

    dispatch(ActionCreator(ConfigsConstants.FETCH_ORGANISATION_SERVICES_REQUEST));
    if (!organisation && responseData.data.organisations.length === 0) {
      TokenHelper.saveOnboardingStatus('NON_ORGANISATION');
    } else {
      if (!organisation && responseData.data.organisations.length > 0) {
        orgObject = responseData.data.organisations.find(each => each['is_favourite']);
        if (!orgObject) orgObject = responseData.data.organisations[0];
        country = orgObject.country;
        organisation = orgObject.reference;
        role = orgObject.role.name;
      }

      TokenHelper.saveUserRole(role);
      TokenHelper.saveSelectedOrg(organisation);
      TokenHelper.saveSelectedOrgCountry(country);
      if (country) queries.push(ConfigsService.getCountryServices(country));
      if (organisation) queries.push(ProfileService.getOrganisation(organisation));
      if (organisation) queries.push(ConfigsService.getOrganisationServices(organisation));
      if (organisation) queries.push(AnalyticsService.getOnboardingStatus(organisation));
      if (organisation) queries.push(ConfigsService.getTenantPermissions());
      if (queries.length > 0) queryData = await Promise.all(queries);
      dispatch(ActionCreator(ConfigsConstants.FETCH_COUNTRY_SERVICES_SUCCESS, queryData[0]['data']['data']));
      dispatch(ActionCreator(ProfileConstants.FETCH_ORGANISATION_INFO_SUCCESS, queryData[1]['data']['data']));
      dispatch(ActionCreator(ConfigsConstants.FETCH_ORGANISATION_SERVICES_SUCCESS, queryData[2]['data']['data']));
      dispatch(ActionCreator(AnalyticConstants.FETCH_ONBOARDING_SUCCESS, queryData[3]['data']['data']));
      dispatch(ActionCreator(UserConstants.FETCH_TENANT_PERMISSIONS_SUCCESS, queryData[4]['data']['data']));
    }
    dispatch(loadingApp(false));
  } catch (error) {
    dispatch(ActionCreator(ProfileConstants.FETCH_PROFILE_FAILURE));
  }
};

export const getOrganisations = filter => async dispatch => {
  try {
    dispatch(ActionCreator(ProfileConstants.FETCH_ORGANISATIONS_REQUEST));
    const { data: responseData } = await ProfileService.getOrganisations(filter);
    await dispatch(ActionCreator(ProfileConstants.FETCH_ORGANISATIONS_SUCCESS, responseData));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(ProfileConstants.FETCH_ORGANISATIONS_FAILURE));
  }
}

export const getOrganisationInfo = organisationInfo => async dispatch => {
  try {
    dispatch(ActionCreator(ProfileConstants.FETCH_ORGANISATION_INFO_REQUEST));
    const { data: responseData } = await ProfileService.getOrganisation(organisationInfo);
    await dispatch(ActionCreator(ProfileConstants.FETCH_ORGANISATION_INFO_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(ProfileConstants.FETCH_ORGANISATION_INFO_FAILURE));
  }
}

export const getOrganisationMembers = ref => async dispatch => {
  try {
    dispatch(ActionCreator(ProfileConstants.FETCH_ORGANISATION_MEMBERS_REQUEST));
    const { data: responseData } = await ProfileService.getOrganisationMembers(ref);
    await dispatch(ActionCreator(ProfileConstants.FETCH_ORGANISATION_MEMBERS_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(ProfileConstants.FETCH_ORGANISATION_MEMBERS_FAILURE));
  }
}

export const getBusinesses = filter => async dispatch => {
  try {
    dispatch(ActionCreator(ProfileConstants.FETCH_BUSINESS_REQUEST));
    const { data: responseData } = await ProfileService.getBusinesses(filter);
    await dispatch(ActionCreator(ProfileConstants.FETCH_BUSINESS_SUCCESS, responseData));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(ProfileConstants.FETCH_BUSINESS_FAILURE));
  }
}

export const getBusinessInfo = filter => async dispatch => {
  try {
    dispatch(ActionCreator(ProfileConstants.FETCH_BUSINESS_INFO_REQUEST));
    const { data: responseData } = await ProfileService.getBusinesses(filter);
    await dispatch(ActionCreator(ProfileConstants.FETCH_BUSINESS_INFO_SUCCESS, responseData));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(ProfileConstants.FETCH_BUSINESS_INFO_FAILURE));
  }
}

export const getPaymentConfiguration = businessRef => async dispatch => {
  try {
    dispatch(ActionCreator(ProfileConstants.FETCH_BUSINESS_CONFIG_REQUEST));
    const { data: responseData } = await ProfileService.getPaymentConfiguration(businessRef);
    await dispatch(ActionCreator(ProfileConstants.FETCH_BUSINESS_CONFIG_SUCCESS, responseData.data));
  } catch (error) {
    expiredTokenCheck(error, dispatch);
    dispatch(ActionCreator(ProfileConstants.FETCH_BUSINESS_CONFIG_FAILURE));
  }
}

/*********Warehouse *********/
export const getDriverWarehouseArrivals = filter => async dispatch => {
  try {
    dispatch(ActionCreator(ProfileConstants.FETCH_DRIVERS_WAREHOUSE_ARRIVAL_REQUEST));
    const { data: responseData } = await ProfileService.getDriverWarehouseArrivals(filter);
    dispatch(ActionCreator(ProfileConstants.FETCH_DRIVERS_WAREHOUSE_ARRIVAL_SUCCESS, responseData));
  } catch (error) {
    dispatch(ActionCreator(ProfileConstants.FETCH_DRIVERS_WAREHOUSE_ARRIVAL_FAILURE));
  }
};

export const getDriverWarehouseArrivalInformation = filter => async dispatch => {
  try {
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSE_ARRIVAL_INFORMATION_REQUEST));
    const { data: responseData } = await ProfileService.getDriverWarehouseArrivals(filter);
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSE_ARRIVAL_INFORMATION_SUCCESS, responseData.data));
  } catch (error) {
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSE_ARRIVAL_INFORMATION_FAILURE));
  }
};

export const getWarehouses = filter => async dispatch => {
  try {
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSES_REQUEST));
    const { data: responseData } = await ProfileService.getTenancyWarehouses(filter);
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSES_SUCCESS, responseData));
  } catch (error) {
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSES_FAILURE));
  }
};

export const getWarehouse = filter => async dispatch => {
  try {
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSE_REQUEST));
    const { data: responseData } = await ProfileService.getWarehouses(filter);
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSE_SUCCESS, responseData.data));
  } catch (error) {
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSE_FAILURE));
  }
};

export const getWarehouseCatalogues = (warehouseRef, filter) => async dispatch => {
  try {
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSE_CATALOGUE_REQUEST));
    const { data: responseData } = await ProfileService.getWarehouseCatalogues(warehouseRef, filter);
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSE_CATALOGUE_SUCCESS, responseData));
  } catch (error) {
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSE_CATALOGUE_FAILURE));
  }
};

export const getWarehouseDefects = (warehouseRef, filter) => async dispatch => {
  try {
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSE_DEFECTS_REQUEST));
    const { data: responseData } = await ProfileService.getWarehouseDefects(warehouseRef, filter);
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSE_DEFECTS_SUCCESS, responseData));
  } catch (error) {
    dispatch(ActionCreator(ProfileConstants.FETCH_WAREHOUSE_DEFECTS_FAILURE));
  }
};